<template>
  <div>
    <b-card-header class="p-sm-2 p-1">
      <div>
        <b-card-title class="mb-1 text-primary">
          <font-awesome-icon v-if="icon" :icon="icon" fixed-width class="mr-50" />{{ title }}
        </b-card-title>
        <b-card-sub-title v-if="subTitle">
          <span>{{ subTitle }}</span>
        </b-card-sub-title>
      </div>
      <div v-if="description" class="d-flex align-items-center">
        <slot name="optionIcons"></slot>
        <b-link @click="toggleDescription" v-b-tooltip.hover.v-primary
          :title="$t('common.tooltips.informations')">
          <font-awesome-icon v-if="!options.showInfo" :icon="['fas', 'info-circle']" size="2x" transform="shrink-5"
            class="text-primary" fixed-width />
          <font-awesome-icon v-else :icon="['fas', 'chevron-up']" size="2x" transform="shrink-5" class="text-primary"
            fixed-width />
        </b-link>
      </div>
    </b-card-header>
    <b-card-header v-if="options.showInfo" class="pt-0">
      <span class="text-secondary" v-html="description">
      </span>
    </b-card-header>
  </div>
</template>

<script>
import {
  BCardHeader, BCardTitle, BCardSubTitle, VBTooltip, BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    BLink,
  },
  props: {
    id: {
      type: String,
      default: 'header',
    },
    title: {
      type: String,
    },
    subTitle: {
      type: String,
    },
    description: {
      type: String,
    },
    icon: {
      type: Array,
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      options: {
        showInfo: true,
      },
    }
  },
  created() {
    if (this.id !== 'header') {
      if (this.$cookies.isKey('header_' + this.id)) { // eslint-disable-line
        this.options.showInfo = this.$cookies.get('header_' + this.id) === 'true' // eslint-disable-line
      } else {
        this.options.showInfo = true
      }
    }
  },
  methods: {
    toggleDescription() {
      this.options.showInfo = !this.options.showInfo
      if (this.id !== 'header') {
        this.$cookies.set('header_' + this.id, this.options.showInfo, '30d') // eslint-disable-line
      }
    },
  },
}
</script>
